import React from 'react';

class HorzLine extends React.Component {
    render() {
        return (
                <div className="pre-HorzLine" />
        )
    }
}

export default HorzLine;